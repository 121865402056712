import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
        path: '/resorts/:uuid/requirement',
        name: 'resort-requirements',
        component: () => import(
        /* webpackChunkName: "resortRequirements" */ "@/modules/Resorts/Pages/requirements/Requirements.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.requirement.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.requirement.title', active: true },
            ]
        }
    },
    {
        path: '/resorts/:uuid/requirement/create',
        name: 'resort-requirements-create',
        component: () => import(
        /* webpackChunkName: "resortRequirements" */ "@/modules/Resorts/Pages/requirements/EditRequirement.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.requirement.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.requirement.title', url: `/resorts/:uuid/requirement` },
                { title: 'shared.add', active: true },
            ]
        }
    },
    {
        path: '/resorts/:uuid/requirement/edit/:elementUuid',
        name: 'resort-requirements-edit',
        component: () => import(
        /* webpackChunkName: "resortRequirements" */ "@/modules/Resorts/Pages/requirements/EditRequirement.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.requirement.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.requirement.title', url: `/resorts/:uuid/requirement` },
                {
                    title: () => {
                        const requirement = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${requirement.key}`
                    },
                    active: true
                },
            ]
        }
    },
]
