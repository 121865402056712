import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";

export default [
  {
    path: '/apps/:uuid/promo-assets',
    name: 'promoassets',
    component: () => import(
      /* webpackChunkName: "promo-assets" */ "@/modules/Apps/Pages/promo-assets/PromoAsssets.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'promoassets.title',
      drm: [
        { context: 'apps', drm: 'promo_assets' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'promoassets.title', active: true },
      ]
    }
  }
]
