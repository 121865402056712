import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";

export default [
  {
    path: '/resorts/:uuid/photos',
    name: 'resort-photos',
    component: () => import(
      /* webpackChunkName: "resortPhotos" */ "@/modules/Resorts/Pages/photos/Photos.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.photos.title',
      drm: [
        { context: 'resorts', drm: 'usercontent' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: `/resorts/:uuid` },
        { title: 'resorts.photos.title', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/photos/export',
    name: 'export-resort-photos',
    component: () => import(
      /* webpackChunkName: "resortPhotosExportForm" */ "@/modules/Resorts/Pages/photos/forms/PhotosExportForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resort-photos",
      pageTitle: 'resorts.photos.title',
      drm: [
        { context: 'resorts', drm: 'usercontent' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: `/resorts/:uuid` },
        { title: 'resorts.photos.title', url: `/resorts/:uuid/photos` },
        { title: 'apps.photos.exporttitle', active: true },
      ]
    }
  },
]
