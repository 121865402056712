import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";

export default [
  {
    path: '/resorts/:uuid/tracks',
    name: 'resort-tracks',
    component: () => import(
      /* webpackChunkName: "resortTracks" */ "@/modules/Resorts/Pages/tracks/Tracks.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.tracks.title',
      drm: [
        { context: 'resorts', drm: 'usercontent' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: `/resorts/:uuid` },
        { title: 'resorts.tracks.title', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/tracks/export',
    name: 'export-resort-tracks',
    component: () => import(
      /* webpackChunkName: "resortTracksExportForm" */ "@/modules/Resorts/Pages/tracks/forms/TracksExportForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resort-tracks",
      pageTitle: 'resorts.tracks.title',
      drm: [
        { context: 'resorts', drm: 'usercontent' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: `/resorts/:uuid` },
        { title: 'resorts.tracks.title', url: `/resorts/:uuid/tracks` },
        { title: 'apps.tracks.exporttitle', active: true },
      ]
    }
  },
]
