import state from './moduleResortsState.js'
import mutations from './moduleResortsMutations.js'
import actions from './moduleResortsActions.js'
import getters from './moduleResortsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
