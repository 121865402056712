import axios from 'axios'
import firebase from 'firebase/app'

export let publicapi = axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_API_URL,
  headers: { 'x-api-key': process.env.VUE_APP_PUBLIC_API_KEY }
})

publicapi.apiname = 'publicapi'

export let editorapi = axios.create({
  baseURL: process.env.VUE_APP_EDITOR_API_URL,
  headers: { 'x-api-key': process.env.VUE_APP_EDITOR_API_KEY }
})

editorapi.apiname = 'editorapi'

export let adminapi = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API_URL,
  headers: { 'x-api-key': process.env.VUE_APP_ADMIN_API_KEY }
})

adminapi.apiname = 'adminapi'

export let shopapi = axios.create({
  baseURL: process.env.VUE_APP_SHOPS_API,
  headers: {
    'X-identification': 'pgsql'
  }
})

shopapi.apiname = 'shopapi'

let axiosapis = { 
  publicapi,
  editorapi,
  adminapi,
  shopapi,
  'apis' : [publicapi, editorapi,adminapi, shopapi]
}

export default axiosapis

////////////////////////
// Handle token refresh:
////////////////////////

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  //Access token fetched. Notifying subscribers
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

function setToken(config,api,token){
  //Set user-token header or Authorization header depending on the API
  if(['web','publicapi','editorapi', 'adminapi', 'shopapi'].includes(api.apiname)){
    config.headers['user-token'] = token
  }else{
    config.headers['Authorization'] = `Bearer ${token}`
  }
}

//Set request interceptors for each API in order to add firebase token to header 
axiosapis.apis.forEach(api => {
  api.interceptors.request.use(async config => {
  //Retrieve firebase user and set token in axios headers
    firebase.auth().onAuthStateChanged(async function(firebase_user) {
      if (firebase_user != null) {
        await firebase_user.getIdToken().then(function(firebase_token){
          //Simulate token failure
          // if(config.url == "/apps/v3"){
          //   const rand = Math.round(Math.random() * 1); 
          //   console.log(`Rand: ${rand}`)
          //   firebase_token = rand ? firebase_token : 'invalid-token'
          // }
          //console.log("Setting firebase token to: " + firebase_token)
          setToken(config,api,firebase_token)
          
        });
      }
    })
    return config;
  }),
    (error) => Promise.reject(error)
})

const MAX_ATTEMPTS = 3;
let attempts = 0;

//Set response interceptors for each API in order to handle token refresh after 401 response
axiosapis.apis.forEach(api => {
  api.interceptors.response.use(function (response) {
    return response
  }, async function (error) {
    attempts++

    const { config, response } = error
    const originalRequest = config

    if (response && response.status === 200) {
      attempts = MAX_ATTEMPTS
    }

    if (response && response.status === 401 && attempts <= MAX_ATTEMPTS) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        firebase.auth().onAuthStateChanged(async function(firebase_user) {
          if (firebase_user != null) {
            try {
              firebase_user.getIdToken().then(firebase_token => {
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(firebase_token)
              })

            } catch (error) {
              console.log(`Error when obtaining user idToken: ${error}`)
            }
          }else{
            console.log('Firebase user is null')
          }
        })
      }else{
        console.log("Token is already being fetched")
      }
      //Add to subscribers in order to retry original request when token is fetched
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(function(firebase_token){
          setToken(config,api,firebase_token)
          resolve(api(originalRequest))
        }) 
      })                 
      return retryOriginalRequest
    }
    return Promise.reject(error)
  })
})