import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
        path: '/resorts/:uuid/audiences',
        name: 'resort-audiences',
        component: () => import(
        /* webpackChunkName: "resortAudiences" */ "@/modules/Resorts/Pages/audiences/Audiences.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.audiences.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.audiences.title', active: true },
            ]
        }
    },
    {
        path: '/resorts/:uuid/audiences/create',
        name: 'resort-audiences-create',
        component: () => import(
        /* webpackChunkName: "resortAudiences" */ "@/modules/Resorts/Pages/audiences/EditAudience.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.audiences.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.audiences.title', url: `/resorts/:uuid/audiences` },
                { title: 'shared.add', active: true },
            ]
        }
    },
    {
        path: '/resorts/:uuid/audiences/edit/:elementUuid',
        name: 'resort-audiences-edit',
        component: () => import(
        /* webpackChunkName: "resortAudiences" */ "@/modules/Resorts/Pages/audiences/EditAudience.vue"
        ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.audiences.title',
            drm: [
                { context: 'resorts', drm: 'touristic_object_parks_and_attractions' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.audiences.title', url: `/resorts/:uuid/audiences` },
                {
                    title: () => {
                        const audience = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${audience.key}`
                    },
                    active: true
                },
            ]
        }
    },
]
