/*
 * File: userDefaults.js
 * Description: Defaults to user when initializing or reseting state
 * File Created: Monday, 27th April 2020 7:30:44 pm
 * Author: David Huerva
 * -----
 * Last Modified: Tuesday, 28th April 2020 1:08:23 pm
 * Modified By: David Huerva
 */

// *From Auth - Data will be received from auth provider
export default function() { 
  return {
    'uuid':''
  }
}