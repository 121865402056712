import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";

export default [
  {
    path: 'registeredusers',
    name: 'registeredusers',
    component: () => import(
      /* webpackChunkName: "registeredusers" */ "@/modules/Apps/Pages/registered-users/RegisteredUsers.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.registered-users.title',
      drm: [
        { context: 'apps', drm: 'registeredusers' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.registered-users.title', active: true },
      ]
    }
  },
  {
    path: 'registeredusers/export',
    name: 'export-registered-users',
    component: () => import(
      /* webpackChunkName: "registeredusersExportForm" */ "@/modules/Apps/Pages/registered-users/forms/RegisteredUsersExportForm.vue"
    ),
    meta: {
      parent: "registeredusers",
      pageTitle: 'apps.registered-users.title',
      drm: [
        { context: 'apps', drm: 'registeredusers' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getAppPanelTitle, url: `/apps:uuid` },
        { title: 'apps.registered-users.title', url: `/apps:uuid/registeredusers` },
        { title: 'apps.registered-users.exportcsv', active: true },
      ]
    }
  },
]
