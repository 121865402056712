import genericStore from "@/modules/Shops/Store/genericStore";
import { shopapi } from "@/axios.js";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "catalogs"
  },
  getters: {
    ...genericStore.getters,
    getProducts: (state, getters) => id => {
      const catalog = getters.getById(id);
      return catalog ? catalog.products : [];
    },
    getParticipants: (state, getters) => id => {
      const catalog = getters.getById(id);
      return catalog ? catalog.participants : [];
    }
  },
  actions: {
    ...genericStore.actions,
    async addProduct(context, payload) {
      const catalog = context.getters.getById(payload.catalog_id);

      if (!catalog.products) {
        catalog.products = [];
      }

      context.commit("ADD_PRODUCT", { catalog, product: payload });
    },

    async addParticipant(context, payload) {
      const catalog = context.getters.getById(payload.catalog_id);

      if (!catalog.participants) {
        catalog.participants = [];
      }

      context.commit("ADD_PARTICIPANT", { catalog, participant: payload });
    },

    async removeProduct(context, payload) {
      const catalog = context.getters.getById(payload.catalog_id);
      const index = catalog.products.findIndex(
        product => product.id === payload.id
      );

      if (!catalog.products) {
        catalog.products = [];
      }

      context.commit("REMOVE_PRODUCT", { index, catalog });
    },

    async removeParticipant(context, payload) {
      const catalog = context.getters.getById(payload.catalog_id);
      const index = catalog.participants.findIndex(
        participant => participant.id === payload.id
      );

      if (!catalog.participants) {
        catalog.participants = [];
      }

      context.commit("REMOVE_PARTICIPANT", { index, catalog });
    },

    async sortCatalogProducts(context, payload) {
      const catalogId = payload.id
      for (let index = 0; index < payload.productsOrder.length -1; index++) {
        const element = payload.productsOrder[index]
        await shopapi.post(`/api/${version}/catalogs/${catalogId}/products/${element}/position/${index}`)
      }

      context.commit("SORT_PRODUCTS", { products: payload.productsOrder, catalogId: catalogId });
    },

    async sortCatalogParticipants(context, payload) {
      const catalogId = payload.id
      for (let index = 0; index < payload.participantsOrder.length -1; index++) {
        const element = payload.participantsOrder[index]
        await shopapi.post(`/api/${version}/catalogs/${catalogId}/participants/${element}/position/${index}`)
      }

      context.commit("SORT_PARTICIPANTS", { participants: payload.participantsOrder, catalogId: catalogId });
    }
  },

  mutations: {
    ...genericStore.mutations,
    ADD_PRODUCT(state, { catalog, product }) {
      catalog.products.push(product);
    },
    REMOVE_PRODUCT(state, { index, catalog }) {
      catalog.products.splice(index, 1);
    },
    ADD_PARTICIPANT(state, { catalog, participant }) {
      catalog.participants.push(participant);
    },
    REMOVE_PARTICIPANT(state, { index, catalog }) {
      catalog.participants.splice(index, 1);
    },
    SORT_PRODUCTS(state, { products, catalogId }) {
      state.resource.find(r => r.id == catalogId).products.sort(
        (productA, productB) => {
          const indexA = products.indexOf(productA.id)
          const indexB = products.indexOf(productB.id)
          return indexA - indexB
        }
      )
    },
    SORT_PARTICIPANTS(state, { participants, catalogId }) {
      state.resource.find(r => r.id == catalogId).participants.sort(
        (participantA, participantB) => {
          const indexA = participants.indexOf(participantA.id)
          const indexB = participants.indexOf(participantB.id)
          return indexA - indexB
        }
      )
    },
  }
};
