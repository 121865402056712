/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'
import axios from '@/axios'
import AuthServices from '@/modules/Auth/Services/AuthServices.js'
import DrmService from '@/modules/Auth/Services/DrmService.js'
import i18n from '@/i18n.js'
import store from "../../Shared/Store/store";


export default {
  // Skitude Token
  loginUserToken ({dispatch, commit}, payload) {

    return new Promise(async (resolve, reject) => {
        await AuthServices.login(payload.userDetails.email, payload.userDetails.password)
        .then(async response => {
          // If there's a jwt token in the response
          if (typeof(response.data.object) !== 'undefined' && typeof(response.data.object.jwt) !== 'undefined') {
            // Login with Firebase
            try {
              await firebase.auth().signInWithCustomToken(response.data.object.jwt)
            } catch (error) {
              //TODO: Split login code from retrieve user data code
              console.log(`Error logging in user to Firebase: ${error.message}`);
              return reject({message: 'Error logging in user'})
            }


            // Get user account, resorts, apps and shops data
            try {
              dispatch('getUserAccount')
              commit('UPDATE_USER_DRM', await DrmService.getUserPermissions())
            }
            catch( error) {
              dispatch('logout');
              console.log(`Error retrieving user data: ${error.message} `);
              return reject({message: 'Error logging in user'})
            }


            try{
              dispatch('resorts/updateResortInfo',{},{root:true})
            } catch( error) {
              dispatch('logout');
              console.log(`Error retrieving resorts data: ${error.message} `);
              return reject({message: 'Error logging in user'})
            }

            try{
              const appsUuids = store.getters["auth/drm"].apps.map(app => app.uuid)
                dispatch('apps/setApps', appsUuids, { root: true })
            } catch( error) {
                dispatch('logout');
                console.log(`Error retrieving apps data: ${error.message} `);
                return reject({message: 'Error logging in user'})
            }

            router.push(router.currentRoute.query.to || '/')
            resolve(response)

          } else {
            return reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
          console.log(`Error logging in user to retrieve custom token: ${error.message}`)
          return reject({message: 'Error logging in user'})
        })
    })
  },
  getUserAccount ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.publicapi.get('/account/v3?referenceSystem=metric')
        .then(response => {

            // Update user details
            commit('UPDATE_USER_INFO', response.data.object, {root: true})

            if (response.data.object.language != undefined) {
              commit('UPDATE_APPLICATION_LANGUAGE', response.data.object.language, {root: true});
            }
            i18n.locale = response.data.object.language ? response.data.object.language : 'en'
            resolve(response)

        })
        .catch(error => {
          reject(error)
        })
    })
  },

  clearUserData({commit}){
    commit({'type':'RESET_USER_INFO'},{root: true})
  },
  logout({dispatch, commit}){

    // Ff user is logged in via firebase
    const firebaseCurrentUser = firebase.auth().currentUser

    if (firebaseCurrentUser) {
      firebase.auth().signOut();

      commit('UPDATE_APPLICATION_UUID', null, {root: true})
      commit('UPDATE_RESORT_UUID', null, {root: true})
    }

    dispatch('clearUserData');
    router.push(`/${store.getters.applicationLanguage}/login`);
  }
}

