import axios from '@/axios.js'

const getUserPermissions = async () => {
  try {
    const response = await axios.editorapi.get('/drm/v3')
    return response.data.object
  } catch (error) {
    throw new Error(error)
  }
}

export default {
  getUserPermissions
}
