import genericStore from "@/modules/Shops/Store/genericStore";

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "statuses"
  },
  getters: genericStore.getters,
  actions: genericStore.actions,
  mutations: genericStore.mutations
};
