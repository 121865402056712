export function download(path, filename) {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}

export async function urlToBase64(url) {
  const response = await fetch(url)
  const blob = await response.blob()

  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve(reader.result)
    };

    reader.readAsDataURL(blob);
  })
}
