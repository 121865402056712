import state from './moduleAppsState.js'
import mutations from './moduleAppsMutations.js'
import actions from './moduleAppsActions.js'
import getters from './moduleAppsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
