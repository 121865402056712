/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import 'flatpickr/dist/flatpickr.css'

Vue.use(Vuesax)

Vue.use(VueCookieBot, {
  cookieBotID: process.env.VUE_APP_COOKIEBOT_ID,
  blockingMode:'manual'
})

// axios
import apis from "./axios.js"
Vue.prototype.$http = apis

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './modules/Shared/Store/store'

// i18n
import i18n from './i18n'

// Vuexy Admin Filters
import './filters'

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// Swiper (Carousel)
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)

// Firebase
import './firebaseConfig'
import firebase from 'firebase/app'

// Feather font icon
require('./assets/css/iconfont.css')

// Notifications mixin
import '@/modules/Shared/Mixins/notifications.js'

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  },
  installComponents: true
})

// Tool for copy in clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

const IMAGES = require.context('@/assets/', true, /\.(?:jpg|gif|png|svg|webm|mp4|vtt|)$/)

Vue.mixin({
  methods: {
    assetUrl(url)
    {
      return IMAGES('./' + url)
    }
  }
})


Vue.config.productionTip = false

let app;

//Init the firebase user before the app is loaded
firebase.auth().onAuthStateChanged(function ()
{
  //Init axios interceptors


  if (!app) {
    app = new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
