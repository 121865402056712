import state from "./moduleShopsState";
import mutations from "./moduleShopsMutations";
import actions from "./moduleShopsActions";
import getters from "./moduleShopsGetters";
import modules from "./modules";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules
};
