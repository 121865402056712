import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "participants"
  },
  getters: {
    ...genericStore.getters,
    getByCatalogId: state => catalog_id => {
      return state.resource.filter(
        participant => participant.catalog_id === catalog_id
      );
    }
  },
  actions: {
    ...genericStore.actions,

    async create(context, payload) {
      const response = await shopapi.post(
        `/api/${version}/participants`,
        payload
      );

      if (response.status === 200) {
        const participant = response.data;
        context.commit("ADD_TO_EXISTING", participant);

        context.dispatch("shops/catalogs/addParticipant", participant, {
          root: true
        });

        return context.getters.getById(participant.id);
      }

      return null;
    },

    async update(context, payload) {
      const response = await shopapi.put(
        `/api/${version}/participants/${payload.id}`,
        payload
      );

      if (response.status === 200) {
        const index = context.state.resource.findIndex(
          participant => participant.id === payload.id
        );
        context.commit("SET_BY_INDEX", { index, participant: payload });
      }
    },

    async delete(context, payload) {
      const response = await shopapi.delete(
        `/api/${version}/participants/${payload.id}`
      );

      if (response.status === 204) {
        const index = context.state.resource.findIndex(
          participant => participant.id === payload.id
        );
        context.commit("REMOVE_BY_INDEX", index);
        context.dispatch("shops/catalogs/removeParticipant", payload, {
          root: true
        });
      }
    }
  },
  mutations: {
    ...genericStore.mutations
  }
};
