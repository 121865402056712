/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const mutations = {
  UPDATE_USER_DRM(state, payload) {
    if (payload.apps) {
      payload.apps = payload.apps.sort((a, b) => a.name.localeCompare(b.name))
    }
    if (payload.resorts) {
      payload.resorts = payload.resorts.sort((a, b) => a.name.localeCompare(b.name))
    }
    state.drm = payload
  }
}

export default mutations
