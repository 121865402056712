import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: '/resorts/:uuid/maps',
    name: 'maps',
    component: () => import(
      /* webpackChunkName: "maps" */ "@/modules/Resorts/Pages/maps/Maps.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.maps.title',
      drm: [
        { context: 'resorts', drm: 'maps' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.maps.title', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/maps/add',
    name: 'add-map',
    component: () => import(
      /* webpackChunkName: "add-map" */ "@/modules/Resorts/Pages/maps/forms/MapForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.maps.title',
      drm: [
        { context: 'resorts', drm: 'maps' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.maps.title', url: '/resorts/:uuid/maps' },
        { title: 'shared.add', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/maps/edit/:mapUuid',
    name: 'edit-map',
    component: () => import(
      /* webpackChunkName: "edit-map" */ "@/modules/Resorts/Pages/maps/forms/MapForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.maps.title',
      drm: [
        { context: 'resorts', drm: 'maps' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.maps.title', url: '/resorts/:uuid/maps' },
        {
            title: () => {
                const map = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${map.map_key}`
            },
            active: true
        },
      ]
    }
  }
]
