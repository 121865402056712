import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;
const config = {headers: {'Content-Type': 'multipart/form-data'}}

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "snippets"
  },
  getters: genericStore.getters,
  actions: {
    ...genericStore.actions,

    async uploadImage(context, payload) {
      const response = await shopapi.post(`/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/snippets/uploadImage`, payload, config)
        .catch(error => {throw error});
      return response.data;
    },

    removeImage(context, payload) {
      shopapi.post(`/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/snippets/removeImage`, payload)
    }
  },
  mutations: genericStore.mutations
};
