import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [{
    path: 'banners',
    name: 'banners',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/BannersManager.vue"
    ),
    props: () => ({
      type: 'standard'
    }),
    meta: {
      parent: "apps",
      pageTitle: 'banners.tab.coverbanners',
      drm: [
        { context: 'apps', drm: 'banners' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.coverbanners', active: true },
      ]
    }
},
{
    path: 'banners/:bannerUuid',
    name: 'edit-banner',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/forms/BannerForm.vue"
    ),
    props: (route) => ({
        ...route.params,
    }),
    meta: {
      parent: "apps",
      pageTitle: 'banners.tab.coverbanners',
      drm: [
        { context: 'apps', drm: 'banners' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.coverbanners', url: `/apps/:uuid/banners` },
        {
            title: () => {
                const banner = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${banner.reference}`
            },
            active: true
        },
      ]
    }
},
{
    path: 'banners',
    name: 'create-banner',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/forms/BannerForm.vue"
    ),
    props: (route) => ({
        ...route.params
    }),
    meta: {
      parent: "apps",
      pageTitle: 'banners.tab.coverbanners',
      drm: [
        { context: 'apps', drm: 'banners' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.coverbanners', url: `/apps/:uuid/banners` },
        { title: 'shared.add', active: true },
      ]
    }
},
]
