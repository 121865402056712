import i18n from "@/i18n";

const getInitialCampaignState = () => {
  return {
    selection: {
      select_users: false,
      installations: [],
      languages: [],
      registration: { label: i18n.t('apps.campaigns.section_history_table_segmentation_all_users'), code: 'all' },
      favorite_resort: null,
      cta: { label: i18n.t('apps.campaigns.section_cta_option_no_cta'), code: 'none'},
      challenge: null,
      hide: false,
      send_now: true,
      ttl_option: { label: i18n.t('apps.campaigns.section_expiration_date_option_30_days'), code: 720 },
    },

    content: [],
    schedule_date: null,
    ttl: 720,
    edit_mode: false,

    form_options: {
      registration: [
        { label: i18n.t('apps.campaigns.section_history_table_segmentation_all_users'), code: 'all' },
        { label: i18n.t('apps.campaigns.section_history_table_segmentation_only_registered'), code: 'only_registered' },
        { label: i18n.t('apps.campaigns.section_history_table_segmentation_only_unregistered'), code: 'only_unregistered' },
        { label: i18n.t('apps.campaigns.section_history_table_segmentation_favorite_resort'), code: 'favorite_resort' },
      ],
      cta: [
        { label: i18n.t('apps.campaigns.section_cta_option_no_cta'), code: 'none'},
        { label: i18n.t('apps.campaigns.section_cta_option_url'), code: 'url' },
        { label: i18n.t('apps.campaigns.section_cta_option_challenge'), code: 'challenge' },
      ],
      ttl: [
        { label: '+24h', code: 24 },
        { label: '+48h', code: 48 },
        { label: i18n.t('apps.campaigns.section_expiration_date_option_1_week'), code: 168 },
        { label: i18n.t('apps.campaigns.section_expiration_date_option_30_days'), code: 720 },
        { label: i18n.t('apps.campaigns.section_expiration_date_option_custom'), code: 'custom' },
      ]
    }
  }
}


const state = {
  apps: [],
  languages: [],
  campaigns: getInitialCampaignState()
}

export default state
export { getInitialCampaignState }
