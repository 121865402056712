import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: '/resorts/:uuid/webcams',
    name: 'webcams',
    component: () => import(
      /* webpackChunkName: "webcams" */ "@/modules/Resorts/Pages/webcams/Webcams.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.webcams.title',
      drm: [
        { context: 'resorts', drm: 'webcams' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.webcams.title', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/webcams/add',
    name: 'edit-webcam',
    component: () => import(
      /* webpackChunkName: "edit-webcam" */ "@/modules/Resorts/Pages/webcams/forms/WebcamForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.webcams.title',
      drm: [
        { context: 'resorts', drm: 'webcams' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.webcams.title', url: '/resorts/:uuid/webcams' },
        {
            title: () => {
                const webcam = store.getters.routeElement
                return webcam
                    ? `${i18n.t('datatable.button.edit')} ${webcam.name}`
                    : i18n.t('shared.add')
            },
            active: true
        },
      ]
    }
  }
]
