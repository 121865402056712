import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [{
    path: 'banners-interstitials',
    name: 'banners-interstitials',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/BannersManager.vue"
    ),
    props: () => ({
      type: 'interstitial'
    }),
    meta: {
      parent: "apps",
      pageTitle: "banners.tab.interstitialbanners",
      drm: [
        { context: 'apps', drm: 'banners_interstitials' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.interstitialbanners', active: true },
      ]
    }
},
{
    path: 'banners-interstitials/:bannerUuid',
    name: 'edit-banner-interstitial',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/forms/BannerForm.vue"
    ),
    props: (route) => ({
        ...route.params,
        type: 'interstitial'
    }),
    meta: {
      parent: "apps",
      pageTitle: 'banners.tab.interstitialbanners',
      drm: [
        { context: 'apps', drm: 'banners_interstitials' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.interstitialbanners', url: `/apps/:uuid/banners-interstitials` },
        {
            title: () => {
                const banner = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${banner.reference}`
            },
            active: true
        },
      ]
    }
},
{
    path: 'banners-interstitials',
    name: 'create-banner-interstitial',
    component: () =>
    import(
      /* webpackChunkName: "banners" */ "@/modules/Apps/Pages/banners/forms/BannerForm.vue"
    ),
    props: (route) => ({
        ...route.params,
        type: 'interstitial'
    }),
    meta: {
      parent: "apps",
      pageTitle: 'banners.tab.interstitialbanners',
      drm: [
        { context: 'apps', drm: 'banners_interstitials' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'banners.tab.interstitialbanners', url: `/apps/:uuid/banners-interstitials` },
        { title: 'shared.add', active: true },
      ]
    }
},
]
