import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: '/apps/:uuid/contact-phones',
    name: 'contactphones',
    component: () => import(
      /* webpackChunkName: "contact-phones" */ "@/modules/Apps/Pages/contact-phones/ContactPhones.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.contact-phones.title',
      drm: [
        { context: 'apps', drm: 'contactphones' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.contact-phones.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/contact-phones/edit/:contactphoneUuid',
    name: 'edit-contact-phone',
    component: () => import(
      /* webpackChunkName: "contact-phones" */ "@/modules/Apps/Pages/contact-phones/forms/ContactPhoneForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "apps",
      pageTitle: 'apps.contact-phones.title',
      drm: [
        { context: 'apps', drm: 'contactphones' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.contact-phones.title', url: `/apps/:uuid/contact-phones` },
        {
            title: () => {
                const contacPhone = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${contacPhone.phone}`
            },
            active: true
        },
      ]
    },
    beforeEnter (to, from, next) {
      if (!to.params.new && !to.params.contactPhone) {
        next({ name: 'contactphones', params: { uuid: to.params.uuid } })
      }

      next()
    }
  },
  {
    path: '/apps/:uuid/contact-phones/add',
    name: 'add-contact-phone',
    component: () => import(
      /* webpackChunkName: "contact-phones" */ "@/modules/Apps/Pages/contact-phones/forms/ContactPhoneForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "apps",
      pageTitle: 'apps.contact-phones.title',
      drm: [
        { context: 'apps', drm: 'contactphones' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.contact-phones.title', url: `/apps/:uuid/contact-phones` },
        { title: 'apps.contact-phone.title', active: true },
      ]
    }
  }
]
