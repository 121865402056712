import axios from '@/axios.js'
import router from "@/router";

const cache = {}

export default {
  getCampaigns: async (filters = [], page = 1) => {
    let query = ''
    if (filters.length > 0) {
      query += ',' + filters.map(filter => {
        return `${filter.field}:${filter.value}`
      }).join(',')
    }

    try {
        const response = await axios.editorapi.get(`/v4/campaigns/`, { params: {
            q: query,
            per_page: 30,
            sort_by: 'to_be_delivered_at',
            sort_type: 'desc',
            page
          }
        })

        return response.data.object
    } catch (error) {
        throw new Error(error)
    }
  },
  getCampaign: async (campaignUuid) => {
    try {
        const response = await axios.editorapi.get(`/v4/campaigns/${campaignUuid}`)
        return response.data.object
    } catch (error) {
        throw new Error(error)
    }
  },
  getInstallations: async (filters = [], page = 1) => {
    let query = `application_uuid:${router.currentRoute.params.uuid}`
    if (filters.length > 0) {
      query += ',' + filters.map(filter => {
        return `${filter.field}:${filter.value}`
      }).join(',')
    }

    try {
      const response = await axios.editorapi.get(`installations/v3/`, { params: {q: query, page} })
      return response.data.object
    } catch (error) {
      throw new Error(error)
    }
  },
  countInstallations: async (options) => {
    options.application_uuid = router.currentRoute.params.uuid
    let query = Object.entries(options).map(option => {
      return `${option[0]}:${option[1]}`
    }).join(',')

    try {
      const isCached = cache.hasOwnProperty(query) && cache[query].expiry_date > Date.now()
      let response;

      if (isCached) {
        response = cache[query].response
      } else {
        response = await axios.editorapi.get(`installations/v3/`, { params: {q: query} })
        cache[query] = {
          response,
          expiry_date: new Date(Date.now() + 60000) // now +1 minute
        }
      }

      return response.data.object.total
    } catch (error) {
      throw new Error(error)
    }
  },

  getChallenges: async (language = 'en') => {
    try {
      const response = await axios.editorapi.get(`apps/v3/${router.currentRoute.params.uuid}/challenges`, { params: { language } })
      return response.data.object
    } catch (error) {
      throw new Error(error)
    }
  },

  scheduleCampaign: async (campaignUuid, payload) => {
    try {
      await axios.editorapi.post(`/v4/campaigns/${campaignUuid}`, payload)
    } catch (error) {
      throw new Error(error)
    }
  },

  editCampaign: async (campaignUuid, payload) => {
    try {
      await axios.editorapi.patch(`/v4/campaigns/${campaignUuid}`, payload)
    } catch (error) {
      throw new Error(error)
    }
  },

  deleteCampaign: async (campaignUuid) => {
    try {
      await axios.editorapi.delete(`/v4/campaigns/${campaignUuid}`)
    } catch (error) {
      throw new Error(error)
    }
  },
}


