import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "mail"
  },
  getters: genericStore.getters,
  actions: {
    ...genericStore.actions,

    async sendTestMail(context, payload){
        const response = await shopapi.post(
            `/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/send_test_email`,
            payload
        );

        return response.data
    }
  },
  mutations: genericStore.mutations
};