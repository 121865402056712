import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: 'geofences',
    name: 'geofences',
    component: () =>
    import(
      /* webpackChunkName: "geofences" */ "@/modules/Apps/Pages/geofences/Geofences.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.geofences.title',
      drm: [
        { context: 'apps', drm: 'geofence' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.geofences.title', active: true },
      ]
    }
  },
  {
    path: 'geofences/add',
    name: 'create-geofence',
    component: () =>
    import(
      /* webpackChunkName: "geofences" */ "@/modules/Apps/Pages/geofences/GeofenceForm.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'geofences.add-geofences.title',
      drm: [
        { context: 'apps', drm: 'geofence' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.geofences.title', url: `/apps/:uuid/geofences` },
        { title: 'geofences.add-geofences.title', active: true },
      ]
    }
  },
  {
    path: 'geofences/:geofenceUuid',
    name: 'edit-geofence',
    component: () =>
    import(
      /* webpackChunkName: "geofences" */ "@/modules/Apps/Pages/geofences/GeofenceForm.vue"
    ),
    props: (route) => ({
        ...route.params
    }),
    meta: {
      parent: "apps",
      pageTitle: 'geofences.update-geofences.title',
      drm: [
        { context: 'apps', drm: 'geofence' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.geofences.title', url: `/apps/:uuid/geofences` },
        {
            title: () => {
                const geofence = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${geofence.reference}`
            },
            active: true
        },
      ]
    }
  },
]
