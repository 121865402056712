import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";

export default [
  {
    path: '/apps/:uuid/photos',
    name: 'app-photos',
    component: () => import(
      /* webpackChunkName: "appPhotos" */ "@/modules/Apps/Pages/photos/Photos.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.photos.title',
      drm: [
        { context: 'apps', drm: 'takenphotos' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.photos.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/photos/export',
    name: 'export-app-photos',
    component: () => import(
      /* webpackChunkName: "appPhotosExportForm" */ "@/modules/Apps/Pages/photos/forms/PhotosExportForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "app-photos",
      pageTitle: 'apps.photos.title',
      drm: [
        { context: 'apps', drm: 'takenphotos' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.photos.title', url: `/apps/:uuid/photos` },
        { title: 'apps.photos.exporttitle', active: true },
      ]
    }
  },
]
