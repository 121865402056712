/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import userDefaults from "./userDefaults";

const mutations = {


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },



  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
    }
    else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url == payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp                 = false
    let lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited    = state.starredPages.slice(0, 10)
    state.starredPages           = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay       = val   },
  UPDATE_PRIMARY_COLOR(state, val)   { state.themePrimaryColor = val   },
  UPDATE_THEME(state, val)           { state.theme             = val   },
  UPDATE_WINDOW_WIDTH(state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY           = val   },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  // Updates user info in state
  UPDATE_USER_INFO(state, payload) {
    state.AppActiveUser = {
      ...state.AppActiveUser,
      // https://stackoverflow.com/a/38340730/3619496
      ...Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null))
    }
  },
  UPDATE_USER_AVATAR(state, avatar) {
    for (const dimension of Object.keys(state.AppActiveUser.avatar)) {
      state.AppActiveUser.avatar[dimension] = avatar
    }
  },

  RESET_USER_INFO(state){
    state.AppActiveUser = userDefaults();
  },

  UPDATE_DISPLAY_NAME (state, displayName) {
    // Store data in localStorage
    state.AppActiveUser['displayName'] = displayName
  },

  UPDATE_APPLICATION_LANGUAGE (state, language) {
    state.applicationLanguage = language;
  },

  UPDATE_APPLICATION_UUID(state, uuid) {
    state.applicationUuid = uuid
  },

  UPDATE_RESORT_UUID(state, uuid) {
    state.resortUuid = uuid
  },

  SET_ROUTE_ELEMENT(state, element) {
    state.routeElement = element
  },
  
  UPDATE_SPOTLIO_LANGUAGES(state, languages) {
    state.spotlioLanguages = languages
  }
}

export default mutations

