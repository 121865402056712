import i18n from '@/i18n';
import store from "@/modules/Shared/Store/store";
import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";
export default [
  {
    path: '/apps/:uuid/configurator',
    name: 'Configurator',
    component: () => import("@/modules/Apps/Pages/configurator/Configurator.vue"),
    meta: {
      parent: "apps",
      pageTitle: 'apps.configurator.title',
      drm: [
        { context: 'apps', drm: 'app_configurator' },
        { context: 'admin', drm: 'apps' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/menu-list',
    name: 'Menu-List',
    component: () => import("@/modules/Apps/Pages/configurator/MenuListPage.vue"),
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.menuList.title',
      drm: [
        { context: 'apps', drm: 'app_configurator' },
        { context: 'admin', drm: 'apps' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid`},
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        {
            title: () => {
                const version = store.getters.routeElement
                return `${version.version_major} ${version.description} - ${i18n.t('apps.configurator.menuList.title')}`
            },
            active: true
        },
      ]
    },
  },

  {
    path: '/apps/:uuid/configurator/new-version',
    name: 'new-version',
    component: () => import("@/modules/Apps/Pages/configurator/ManageVersion.vue"),
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.new-version.title',
      drm: [
        { context: 'apps', drm: 'app_configurator' },
        { context: 'admin', drm: 'apps' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        { title: 'apps.configurator.new-version.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/edit-version',
    name: 'edit-version',
    component: () => import("@/modules/Apps/Pages/configurator/ManageVersion.vue"),
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.edit-version.title',
      drm: [
        { context: 'admin', drm: 'apps' },
        { context: 'apps', drm: 'app_configurator' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        { title: 'apps.configurator.edit-version.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/home-layout-list',
    name: 'Layout-List',
    component: () => import("@/modules/Apps/Pages/configurator/LayoutList.vue"),
    props: true,
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.layoutList.title',
      drm: [
        { context: 'admin', drm: 'apps' },
        { context: 'apps', drm: 'app_configurator' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        {
            title: () => {
                const version = store.getters.routeElement
                return `${version.version_major} ${version.description} - ${i18n.t('apps.configurator.layoutList.title')}`
            },
            active: true
        }
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/style-menu',
    name: 'Style-Menu',
    component: () => import(
      /* webpackChunkName: "style-menu" */ "@/modules/Apps/Pages/configurator/StyleMenu.vue"
    ),
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.styleMenu.title',
      drm: [
        { context: 'admin', drm: 'apps' },
        { context: 'apps', drm: 'app_configurator' },
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        {
            title: () => {
                const version = store.getters.routeElement
                return `${version.version_major} ${version.description} - ${i18n.t('apps.configurator.styleMenu.title')}`
            },
            active: true
        }
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/resources',
    name: 'resources',
    component: () => import(
      "@/modules/Apps/Pages/configurator/Resources.vue"
    ),
    meta: {
      parent: "Configurator",
      pageTitle: 'apps.configurator.resources.title',
      drm: [
        { context: 'admin', drm: 'apps' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        {
            title: () => {
                const version = store.getters.routeElement
                return `${version.version_major} ${version.description} - ${i18n.t('apps.configurator.resources.title')}`
            },
            active: true
        }
      ]
    }
  },
  {
    path: '/apps/:uuid/configurator/:versionUuid/resource-gallery',
    name: 'gallery-resources',
    component: () => import(
      "@/modules/Apps/Pages/configurator/GalleryResources.vue"
    ),
    meta: {
      parent: "resources",
      pageTitle: 'apps.configurator.resources.gallery.title',
      drm: [
        { context: 'admin', drm: 'apps' },
        { context: 'apps', drm: 'app_configurator' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.configurator.title', url: `/apps/:uuid/configurator` },
        {
            title: () => {
                const version = store.getters.routeElement
                return `${version.version_major} ${version.description} - ${i18n.t('apps.configurator.resources.gallery.title')}`
            },
            active: true
        }
      ]
    }
  }
]
