import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
      path: '/resorts/:uuid/urls',
      name: 'urls',
      component: () =>
      import(
      /* webpackChunkName: "urls" */ "@/modules/Shared/Components/urls/Urls.vue"
      ),
      props: (route) => ({
        ...route.params,
        context: 'resort'
      }),
      meta: {
        parent: "resorts",
        pageTitle: 'resorts.urls.title',
        drm: [
          { context: 'resorts', drm: 'urls' }
        ],
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: '/resorts/:uuid' },
          { title: 'resorts.urls.title', active: true },
        ]
      }
    },
    {
      path: '/resorts/:uuid/urls/:urlUuid',
      name: 'edit-url',
      component: () => import(
        /* webpackChunkName: "urls" */ "@/modules/Shared/Components/urls/EditUrl.vue"
      ),
      props: (route) => ({
        ...route.params
      }),
      meta: {
        parent: "resorts",
        pageTitle: 'urls.edit.title',
        drm: [
          { context: 'resorts', drm: 'urls' }
        ],
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: '/resorts/:uuid' },
          { title: 'resorts.urls.title', url: '/resorts/:uuid/urls' },
          {
              title: () => {
                  const url = store.getters.routeElement
                  return `${i18n.t('datatable.button.edit')} ${url.key}`
              },
              active: true
          },
        ]
      }
    }
]
