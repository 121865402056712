import { getInitialCampaignState } from "./moduleAppsState";

const mutations = {
  SET_APPS(state, apps) {
    state.apps = apps
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages.map((language) => {
      let acronym = language.acronym;
      if (acronym == 'es_419') {
        acronym = 'es_ar'
      }
      return {
        ...language,
        acronym: acronym,
        name: language.is_default ? language.name + '*' : language.name
      }
    })
    return state
  },

  SET_CAMPAIGN_SELECT_USERS(state, active) {
    state.campaigns.selection.select_users = active
    return state
  },

  SET_CAMPAIGN_INSTALLATIONS(state, installations) {
    state.campaigns.selection.installations = installations
    return state
  },

  SET_CAMPAIGN_REGISTRATION(state, active) {
    state.campaigns.selection.registration = active
    return state
  },

  SET_CAMPAIGN_FAVORITE_RESORT(state, favoriteResort) {
    state.campaigns.selection.favorite_resort = favoriteResort
    return state
  },

  SET_CAMPAIGN_CTA(state, value) {
    state.campaigns.selection.cta = value
    return state
  },

  SET_CAMPAIGN_CHALLENGE(state, value) {
    state.campaigns.selection.challenge = value
    return state
  },

  SET_CAMPAIGN_HIDE(state, value) {
    state.campaigns.selection.hide = value
    return state
  },

  SET_CAMPAIGN_SEND_NOW(state, active) {
    state.campaigns.selection.send_now = active
    return state
  },

  SET_CAMPAIGN_SCHEDULE_DATE(state, date) {
    state.campaigns.schedule_date = date
    return state
  },

  SET_CAMPAIGN_TTL_OPTION(state, value) {
    state.campaigns.selection.ttl_option = value
    return state
  },

  SET_CAMPAIGN_TTL(state, value) {
    state.campaigns.ttl = value
    return state
  },

  ADD_CAMPAIGN_LANGUAGE_CONTENT(state, languageContent) {
    state.campaigns.content = [...state.campaigns.content, languageContent]
  },

  REMOVE_CAMPAIGN_LANGUAGE_CONTENT(state, languageAcronym) {
    state.campaigns.content = state.campaigns.content.filter(languageContent => languageContent.language.acronym !== languageAcronym)
  },

  SELECT_CAMPAIGN_LANGUAGES(state, languages) {
    state.campaigns.selection.languages = languages
    return state
  },

  SET_CAMPAIGN_LANGUAGES(state, languages) {
    state.campaigns.selection.languages = languages
    return state
  },

  SET_CAMPAIGN_CONTENT(state, content) {
    state.campaigns.content = content
    return state
  },

  SET_CAMPAIGN_LANGUAGE_INSTALLATIONS_COUNT(state, {acronym, count}) {
    if (!acronym) {
      state.campaigns.content[0].count = count
      return state
    }

    state.campaigns.content = state.campaigns.content.map(c => {
      if (c.language.acronym === acronym) {
        c.count = count
      }
      return c
    })
    return state
  },

  SET_CAMPAIGN_EDIT_MODE(state, value) {
    state.campaigns.edit_mode = value
    return state
  },

  RESET_CAMPAIGN(state) {
    state.campaigns = getInitialCampaignState()
    return state
  },

  SET_CAMPAIGN_CATEGORY(state, category) {
    state.campaigns.category = category
    return state
  },
}

export default mutations
