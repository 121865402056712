  /*
 * File: AuthServices.js
 * Description:Authentication methods
 * File Created: Thursday, 21st May 2020 3:30:13 pm
 * Author: David Huerva
 * -----
 * Last Modified: Thursday, 21st May 2020 3:31:26 pm
 * Modified By: David Huerva
 */

import axios from '@/axios.js'
import firebase from 'firebase/app'
import store from '@/modules/Shared/Store/store'

export default {
  async checkIfOldBackendSessionExists(phpsessid){
    let response = await axios.web.get(`/login/check-session/session/${phpsessid}`,{withCredentials:true});
    return response.data.response;
  },

  login (email, password) {
    store.dispatch('statistics/resetStatistics');
    return axios.publicapi.post('/custom-auth/v3/skitude/login', {
      email,
      password
    },
    {
      headers:{
        'application-token':process.env.VUE_APP_PUBLIC_API_APP_TOKEN
      }
    })
  },

  async loggedIn() {
    let checkFirebase = new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        resolve(user)
      })
    })

    const user = await checkFirebase
    return user && !user.isAnonymous
  },

  restorePassword: async function (field, value) {
    const response = await axios.publicapi.post('/account/v3/password-forget', { [field]: value })
    return response.status < 300
  },

  updateUserData: async function (values) {
    const response = await axios.publicapi.patch('/account/v3/', values)
    return response.status < 300
  },
  updateUserPasword: async function (values) {
    const response = await axios.publicapi.patch('/account/v3/password', values)
    return response
  },

  updateUserAvatar: async function (values) {
    const response = await axios.publicapi.post('/account/v3/avatar', values,{headers: {'Content-Type': 'multipart/form-data'}})
    return response.status < 300
  }
}
