import { getInitialState } from "./moduleStatisticsState";
import Vue from 'vue';

const mutations = {
  SET_STATISTIC(state, {statisticKey, resourceType, resourceUuid, statistic}) {
    Vue.set(state[resourceType][statisticKey], resourceUuid, statistic)
    return state
  },

  RESET_STATISTICS(state) {
    Object.assign(state, getInitialState())
    return state
  }
}

export default mutations
