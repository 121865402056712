import Info from 'luxon/src/info.js';
import {DateTime} from "luxon";

export const weekdays = () => {
    return Info.weekdays('long', { locale: 'en' }).map((day) => day.toLowerCase())
}

export const formatSeconds = (seconds) => {
    let result = ''
    const d = Math.floor(seconds / (3600*24))
    const [h, m, s] = new Date(seconds * 1000 % (3600*24*1000))
      .toISOString().substr(11, 8)
      .split(':')
      .map(digit => parseInt(digit))

    if (d !== 0) {
      result += d + 'd'
    }
    if (h !== 0) {
      result += ` ${h}h`
    }
    if (m !== 0) {
      result += ` ${m}m`
    }
    if (s !== 0) {
      result += ` ${s}s`
    }

    return result.trim()
}

export const fromDBToTimezone = (dateString, timezone) => {
  return DateTime
    .fromSQL( `${dateString} Europe/Madrid`)
    .setZone(timezone)
}

export const fromLocalToDBString = (dateString) => {
  return DateTime
    .fromMillis((new Date(dateString)).getTime())
    .setZone('Europe/Madrid')
    .toFormat('yyyy-MM-dd HH:mm:ss')
}

export const fromISOtoDBString = (ISOString) => {
  return DateTime.fromISO(ISOString)
    .setZone('Europe/Madrid')
    .toFormat('yyyy-MM-dd HH:mm:ss')
}

export const getTimezoneOffset = (timezone) => {
  return DateTime.now()
    .setZone(timezone)
    .toString()
    .slice(-6)
}
