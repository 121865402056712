import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: 'content',
    name: 'content',
    component: () => import('@/modules/Resorts/Pages/content/Contents.vue'),
    props: {
      path: 'resort/pages/index',
    },
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.content.title',
      drm: [
        { context: 'resorts', drm: 'editor' }
      ],
      breadcrumb: [
        {title: 'Home', url: '/'},
        {title: getResortPanelTitle, url: '/resorts/:uuid'},
        {title: 'resorts.content.title', active: true},
      ]
    }
  },
  {
    path: 'content/:page_uuid/:lang',
    name: 'edit-content',
    component: () => import('@/modules/Resorts/Pages/content/forms/ContentForm.vue'),
    props: (route) => ({
        ...route.params
    }),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.content.title',
      drm: [
        { context: 'resorts', drm: 'editor' }
      ],
      breadcrumb: [
        {title: 'Home', url: '/'},
        {title: getResortPanelTitle, url: '/resorts/:uuid'},
        {title: 'resorts.content.title', url: '/resorts/:uuid/content'},
        {
            title: () => {
                const content = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${content.title}`
            },
            active: true
        },
      ]
    }
  },
]
