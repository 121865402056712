import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "capacities",
    _table: {}
  },
  getters: {
    ...genericStore.getters,
    table: state => state._table
  },
  actions: {
    ...genericStore.actions,
  
    async getCapacityTable(context){
      const response = await shopapi.get(
          `/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/capacities/table`
      );

      context.commit("SET_TABLE", response.data.data);
    }
  },
  mutations: {
    ...genericStore.mutations,
  
    SET_TABLE(state, payload) {
      state._table = payload;
    },
  }
};
