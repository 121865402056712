import Vue from "vue";
import i18n from "@/i18n";
import store from "./modules/Shared/Store/store";

Vue.filter('ellipsis', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }
  return value
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  const arr = value.split(" ");
  const capitalized_array = [];
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("title", function(value, replacer = "_") {
  if (!value) return "";
  value = value.toString();

  const arr = value.split(replacer);
  const capitalized_array = [];
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("truncate", function(value, limit) {
  return value.substring(0, limit);
});

Vue.filter("tailing", function(value, tail) {
  return value + tail;
});

Vue.filter("time", function(value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return `${hours}:${min} ${time}`;
    }
    return `${hours}:${min}`;
  }
});

Vue.filter("date", function(value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return `${date} ${month}`;
  else return `${date} ${month} ${year}`;
});

Vue.filter("datetime", function(value, withTime = false) {
  if (value) {
    const fullDate = new Date(Date.parse(value));
    const year = fullDate.getFullYear();
    const month = fullDate.getMonth() + 1;
    const day = fullDate.getDate();

    if (!withTime) {
      return `${day}/${month}/${year}`;
    }

    const hour = fullDate.getHours();
    let minute = fullDate.getMinutes();
    let seconds = fullDate.getSeconds();

    // Add 0 padding to minutes & seconds
    if (minute < 10) {
      minute = `0${minute}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    const date = `${day}/${month}/${year}`;
    const time = `${hour}:${minute}:${seconds}`;

    // If there's no time, don't show it
    if (time === "0:00:00") {
      return date;
    }

    return `${date} ${time}`;
  }
});

Vue.filter("month", function(val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`;
  }
});

Vue.filter("csv", function(value) {
  return value.join(", ");
});

Vue.filter("filter_tags", function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function(num) {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
});

Vue.filter("uri", function(value) {
  return `<a target="_blank" href="${value}">${value}</a>`;
});

Vue.filter("table_format", function(value, prop, resortId, routeName) {
  // let currency = store.getters["shops/resorts/getById"](parseInt(resortId))
  //   .currency;
  let currency = store.getters["shops/resorts/resort"].currency;

  if (
    prop.includes("created") ||
    prop.includes("updated") ||
    (prop.includes("date") && !prop.includes("_ip"))
  ) {
    let longFormat = false;

    // Also show time on these views
    if (
      ["OrderDetails", "Orders", "Payments", "Customers", "OrderInfo"].includes(routeName)
    ) {
      longFormat = true;
    }

    return Vue.filter("datetime")(value, longFormat);
  }

  if (prop.includes("name") && prop != "username") {
    return Vue.filter("capitalize")(value);
  }

  if (typeof value === "string" && value.startsWith("http")) {
    return Vue.filter("uri")(value);
  }

  // Money
  // TODO: Pending fetching the currencyCode from the resort
  if (routeName !== "Orders" && routeName !== "VariablePricing" && routeName !== "Customers" && routeName !== "Promotions") {
    if (prop.includes("amount") || prop.includes("total_")) {
      return Vue.filter("money")(value, currency, i18n.locale);
    }
  }

  return value;
});

/**
 * Displays a monetary amount in the given locale
 *
 * Examples:
 *   - (29.99, "EUR", "es-ES") => "€29.99"
 *   - (29, "EUR", "en-US") => "29,00 €"
 *
 * @param {string|number} value Numeric value (9.99, 29.99, etc)
 * @param {string} currency ISO currency code (EUR, USD, etc)
 * @param {string} locale Locale (es-ES, en-US, etc)
 */
Vue.filter("money", function(value, currency, locale) {
  let options = {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency,
    maximumFractionDigits: 2 // Two decimals
  };

  return new Intl.NumberFormat(locale, options).format(parseFloat(value));
});

/**
 * @param string value
 */
Vue.filter("table_title", function(value) {
  if (!value) {
    return "";
  }

  // If the field is a nested field (ex: "product.catalog"), only keep the last
  // string after the dot (.)
  if (value.includes(".")) {
    value = value.split(".").pop();
  }

  var translated = i18n.t("shops."+ value);
  if(typeof translated === 'object') return i18n.t("shops." + value + ".0")
  return translated;
});

/**
 * @param string reference_system (metric or imperial)
 * @param string type (height speed distance temperature)
 */
Vue.filter("value_system_converter", function(value, reference_system_destination, type) {

  if (value !== null && (reference_system_destination !== 'metric' || reference_system_destination !== 'imperial')) {
    if(type === 'depth') {
      if(reference_system_destination === 'metric') {
        return (value * 2.54).toFixed(2)
      } else {
        return (value / 2.54).toFixed(2)
      }
    }

    if(type === 'speed' || type === 'distance') {
      if(reference_system_destination === 'metric') {
        return Math.round(value * 1.60934)
      } else {
        return Math.round(value / 1.60934)
      }
    }

    if(type === 'temperature') {
        if (reference_system_destination === 'metric') {
            return (value * 9 / 5) + 32
        } else {
            return (value - 32) * 5 / 9
        }
    }

    if(type === 'height') {
        if(reference_system_destination === 'metric') {
            return (value * 0.3048).toFixed(2)
        } else {
            return (value / 0.3048).toFixed(2)
        }
    }
  }

  return value
});

Vue.filter("unit_system_converter", function(value, reference_system) {
    if(value === 'depth') {
      return reference_system === 'metric' ? 'cm' : 'in'
    }
    if(value === 'speed') {
      return reference_system === 'metric' ? 'km/h' : 'mph'
    }
    if(value === 'distance') {
      return reference_system === 'metric' ? 'km' : 'mi'
    }

    if(value === 'temperature') {
      return reference_system==='metric'?'ºC':'ºF'
    }

    if(value === 'height') {
        return reference_system === 'metric' ? 'm' : 'ft'
    }

});
Vue.filter("htmlspecialchars_decode", function(string) {
  return string.toString()
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
});
