import axios from '@/axios.js'

export default {
  getLanguages : async (appUuid) => {
    try {
      const response = await axios.editorapi.get(`apps/v3/${appUuid}/languages`)
      return response.data.object.sort((a, b) => a.name.localeCompare(b.name))
    } catch (error) {
        throw new Error(error)
    }
  },

  getResorts : async (appUuid) => {
    try {
      const response = await axios.editorapi.get(`apps/v3/${appUuid}/resorts`)
      return response.data.object.data
    } catch (error) {
      throw new Error(error)
    }
  },

  updateApp : async (appUuid) => {
    await axios.editorapi.post(`apps/v3/configuration/${appUuid}/generate`)
  },
  
  basicAppInfo : async (appUuid) => {
    return await axios.editorapi.get(`v4/apps/${appUuid}`)
  },
}
