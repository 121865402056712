import store from '@/modules/Shared/Store/store'
import axios from '@/axios.js'

export default {
  async getStatistic({commit}, {statisticKey, resourceType, resourceUuid}) {
    try {
      const response = await axios.editorapi.get(`/statistics/v3/${statisticKey}/${resourceType}/${resourceUuid}`)
      const resourceDrm = store.getters['auth/drmResource'](resourceType + 's', resourceUuid)
      const statistic = {
        ...response.data.object,
        resourceName: resourceDrm.name,
        resourceType,
        resourceUuid,
        createdAt: new Date(),
        key: statisticKey
      }

      commit('SET_STATISTIC', {
        statisticKey,
        resourceType,
        resourceUuid,
        statistic
      })
    } catch (error) {
      console.error(error)
    }
  },

  resetStatistics({commit}) {
    commit('RESET_STATISTICS')
  }
}

