import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [

    {
        path: '/resorts/:uuid/events',
        name: 'ToEvents',
        component: () => import(
          /* webpackChunkName: "touristic objects" */ "@/modules/Resorts/Pages/touristicObjects/TouristicObjects.vue"
        ),
        meta: {
          parent: "resorts",
          pageTitle: 'resorts.touristic-objects.events.cardTitle',
          drm: [
            { context: 'resorts', drm: 'touristic_object_events'}
          ],
          type: "events",
          breadcrumb: [
            { title: 'home', url: '/' },
            { title: getResortPanelTitle, url: '/resorts/:uuid' },
            { title: 'resorts.touristic-objects.events.title', active: true },
          ]
        }
    },
    {
        path: '/resorts/:uuid/services',
        name: 'ToServices',
        component: () => import(
          /* webpackChunkName: "touristic objects" */ "@/modules/Resorts/Pages/touristicObjects/TouristicObjects.vue"
        ),
        meta: {
          parent: "resorts",
          pageTitle: 'resorts.touristic-objects.services.cardTitle',
          drm: [
            { context: 'resorts', drm: 'touristic_object_services'}
          ],
          type: 'services',
          breadcrumb: [
            { title: 'home', url: '/' },
            { title: getResortPanelTitle, url: '/resorts/:uuid' },
            { title: 'resorts.touristic-objects.services.title', active: true },
          ]
        }
    },
    {
        path: '/resorts/:uuid/facilities',
        name: 'ToFacilities',
        component: () => import(
            /* webpackChunkName: "touristic objects" */ "@/modules/Resorts/Pages/touristicObjects/TouristicObjects.vue"
            ),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.touristic-objects.services.cardTitle',
            drm: [
                { context: 'resorts', drm: 'touristic_object'}
            ],
            type: 'facilities',
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.touristic-objects.facilities.title', active: true },
            ]
        }
    },
    {
      path: '/resorts/:uuid/services/:touristicObjectUuid/edit',
      name: 'edit-touristicObject-services',
      component: () => import(
        /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/EditTouristicObjects.vue"
      ),
      props: (route) => ({
          ...route.params
      }),
      meta: {
        parent: "resorts",
        drm: [
          { context: 'resorts', drm: 'touristic_object_services'}
        ],
        type: "services",
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: `/resorts/:uuid` },
          { title: 'resorts.touristic-objects.services.title', url: `/resorts/:uuid/services` },
          {
              title: () => {
                  const touristicObject = store.getters.routeElement
                  return touristicObject
                      ? `${i18n.t('datatable.button.edit')} ${touristicObject.name}`
                      : i18n.t('shared.add')
              },
              active: true
          },
        ]
      }
    },
    {
      path: '/resorts/:uuid/events/:touristicObjectUuid/edit',
      name: 'edit-touristicObject-events',
      component: () => import(
        /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/EditTouristicObjects.vue"
      ),
      props: (route) => ({
          ...route.params
      }),
      meta: {
        parent: "resorts",
        drm: [
          { context: 'resorts', drm: 'touristic_object_events'}
        ],
        type: "events",
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: `/resorts/:uuid` },
          { title: 'resorts.touristic-objects.events.title', url: `/resorts/:uuid/events` },
          {
              title: () => {
                  const touristicObject = store.getters.routeElement
                  return touristicObject
                      ? `${i18n.t('datatable.button.edit')} ${touristicObject.name}`
                      : i18n.t('shared.add')
              },
              active: true
          },
        ]
      }
    },
    {
        path: '/resorts/:uuid/facilities/:touristicObjectUuid/edit',
        name: 'edit-touristicObject-facilities',
        component: () => import(
            /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/EditTouristicObjects.vue"
            ),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "resorts",
            drm: [
                { context: 'resorts', drm: 'touristic_object'}
            ],
            type: "facilities",
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: getResortPanelTitle, url: `/resorts/:uuid` },
                { title: 'resorts.touristic-objects.facilities.title', url: `/resorts/:uuid/facilities` },
                {
                    title: () => {
                        const touristicObject = store.getters.routeElement
                        return touristicObject
                            ? `${i18n.t('datatable.button.edit')} ${touristicObject.name}`
                            : i18n.t('shared.add')
                    },
                    active: true
                },
            ]
        }
    },
    {
      path: '/resorts/:uuid/events/:touristicObjectUuid/view',
      name: 'view-touristicObject-events',
      component: () => import(
        /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/ViewTouristicObjects.vue"
        ),
      meta: {
        parent: "resorts",
        drm: [
          { context: 'resorts', drm: 'touristic_object_events'}
        ],
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: `/resorts/:uuid` },
          { title: 'resorts.touristic-objects.events.title', url: `/resorts/:uuid/events` },
          { title: 'resorts.touristic-objects.view.breadcrumbTitle', active: true },
        ]
      }
    },
    {
      path: '/resorts/:uuid/services/:touristicObjectUuid/view',
      name: 'view-touristicObject-services',
      component: () => import(
        /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/ViewTouristicObjects.vue"
        ),
      meta: {
        parent: "resorts",
        drm: [
          { context: 'resorts', drm: 'touristic_object_services'}
        ],
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: `/resorts/:uuid` },
          { title: 'resorts.touristic-objects.services.title', url: `/resorts/:uuid/services` },
          { title: 'resorts.touristic-objects.view.breadcrumbTitle', active: true },
        ]
      }
    },
    {
        path: '/resorts/:uuid/facilities/:touristicObjectUuid/view',
        name: 'view-touristicObject-facilities',
        component: () => import(
            /* webpackChunkName: "edit-touristicObject" */ "@/modules/Resorts/Pages/touristicObjects/ViewTouristicObjects.vue"
            ),
        meta: {
            parent: "resorts",
            drm: [
                { context: 'resorts', drm: 'touristic_object'}
            ],
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: getResortPanelTitle, url: `/resorts/:uuid` },
                { title: 'resorts.touristic-objects.facilities.title', url: `/resorts/:uuid/facilities` },
                { title: 'resorts.touristic-objects.view.breadcrumbTitle', active: true },
            ]
        }
    }


]
