import genericStore from "@/modules/Shops/Store/genericStore";

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "period_ranges"
  },
  getters: {
    ...genericStore.getters,
    getByPeriodId: state => period_id => {
      return state.resource.filter(
        period_range => period_range.period_id === period_id
      );
    }
  },
  actions: genericStore.actions,
  mutations: genericStore.mutations
};
