import genericStore from "@/modules/Shops/Store/genericStore";

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "promotions"
  },
  getters: genericStore.getters,
  actions: {
    ...genericStore.actions,
    async createVariablePricing() {}
  },
  mutations: genericStore.mutations
};
