import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,
  state: {
    ...genericStore.state,
    module: "products"
  },
  getters: {
    ...genericStore.getters,
    getByCatalogId: state => catalog_id => {
      return state.resource.filter(
        product => product.catalogs[0] === catalog_id
      );
    }
  },
  actions: {
    ...genericStore.actions,

    async create(context, payload) {
      const response = await shopapi.post(`/api/${version}/products`, payload);

      if (response.status === 200) {
        const product = Object.assign(response.data, {
          catalogs: payload.catalogs
        });
        context.commit("ADD_TO_EXISTING", product);

        // Añadir a la store
        context.dispatch("shops/catalogs/addProduct", product, { root: true });

        return context.getters.getById(product.id);
      }

      return null;
    },

    async delete(context, payload) {
      const response = await shopapi.delete(
        `/api/${version}/products/${payload.id}`
      );

      if (response.status === 204) {
        const index = context.state.resource.findIndex(
          product => product.id === payload.id
        );
        context.commit("REMOVE_BY_INDEX", index);
        context.dispatch("shops/catalogs/removeProduct", payload, {
          root: true
        });
      }
    }
  },

  mutations: {
    ...genericStore.mutations
  }
};
