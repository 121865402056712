import store from '@/modules/Shared/Store/store'

const fetchStatistics = async (statisticKey, resourceType, resourceUuid = null) => {
  let resources = getResourcesWithStatisticsDrm(resourceType)

  if (resourceUuid) {
    resources = [resources.find(resource => resource.uuid === resourceUuid)]
  }

  for await (let resource of resources) {
    if (!store.getters['statistics/hasStatistic'](statisticKey, resourceType, resource.uuid)) {
      await store.dispatch('statistics/getStatistic', {
        statisticKey,
        resourceType,
        resourceUuid: resource.uuid
      })
    }
  }
}

const getResourcesWithStatisticsDrm = (resourceType) => {
  const drm = store.getters['auth/drm'][resourceType + 's']
  return drm.filter(resource => resource.drm.includes('statistics'))
}

export {
  fetchStatistics,
  getResourcesWithStatisticsDrm
}
