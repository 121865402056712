const getInitialState = () => {
  return {
    app: {
      downloads: {},
      active_users: {},
      registered_users: {},
      sessions: {},
      sessions_per_user: {}
    },
    resort: {
      tracked_distance: {},
      tracked_distance_per_difficulty: {}
    },
    shop: {
      sales: {},
      visits: {},
      ctr: {}
    }
  }
}


const state = getInitialState();

export default state
export { getInitialState }
