import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
  {
    path: '/resorts/:uuid/zones',
    name: 'zones',
    component: () => import(
      /* webpackChunkName: "webcams" */ "@/modules/Resorts/Pages/zones/Zones.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.zones.title',
      drm: [
        { context: 'resorts', drm: 'zones' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.zones.title', active: true },
      ]
    }
  },
  {
    path: '/resorts/:uuid/zones/:zoneUuid/edit',
    name: 'edit-zone',
    component: () => import(
      /* webpackChunkName: "edit-webcam" */ "@/modules/Resorts/Pages/zones/forms/ZoneForm.vue"
    ),
    props: (route) => ({
      ...route.params
    }),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.zones.title',
      drm: [
        { context: 'resorts', drm: 'zones' }
      ],
      breadcrumb: [
        { title: 'home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.zones.title', url: '/resorts/:uuid/zones' },
        {
            title: () => {
                const zone = store.getters.routeElement
                return `${i18n.t('datatable.button.edit')} ${zone.name}`
            },
            active: true
        },
      ]
    }
  },
    {
        path: '/resorts/:uuid/zones/create',
        name: 'create-zone',
        component: () => import(
            /* webpackChunkName: "edit-webcam" */ "@/modules/Resorts/Pages/zones/forms/ZoneForm.vue"
            ),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "resorts",
            pageTitle: 'resorts.zones.title',
            drm: [
                { context: 'resorts', drm: 'zones' }
            ],
            breadcrumb: [
                { title: 'home', url: '/' },
                { title: getResortPanelTitle, url: '/resorts/:uuid' },
                { title: 'resorts.zones.title', url: '/resorts/:uuid/zones' },
                { title: 'shared.add', active: true },
            ]
        }
    },
]
