const getters = {
  hasStatistic: state => (statisticKey, resourceType, resourceUuid) => {
    if(state[resourceType][statisticKey].hasOwnProperty(resourceUuid)) {
      const createdAt = new Date(state[resourceType][statisticKey][resourceUuid].createdAt)
      const currentDate = new Date()
      return createdAt.getDate() === currentDate.getDate() && createdAt.getHours() === currentDate.getHours()
    }
    return false;
  },

  statistic: state => (statisticKey, resourceType, resourceUuid) => {
    return resourceUuid
      ? [state[resourceType][statisticKey][resourceUuid]]
      : Object.values(state[resourceType][statisticKey]).sort((a, b) => a.resourceName > b.resourceName ? 1 : -1)
  },

  statisticsKeys: state => (resourceType, resourceUuid = null) => {
    const keys = Object.keys(state[resourceType])
    if (!resourceUuid) return keys

    return keys.filter(key => state[resourceType][key].hasOwnProperty(resourceUuid))
  }
}

export default getters
