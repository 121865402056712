import bannerRoutes from '@/modules/Apps/Routes/sections/bannerRoutes';
import bannerInterstitialRoutes from "@/modules/Apps/Routes/sections/bannerInterstitialRoutes";
import contactphoneRoutes from '@/modules/Apps/Routes/sections/contactphoneRoutes';
import promoassetsRoutes from '@/modules/Apps/Routes/sections/promoassetsRoutes';
import photosRoutes from '@/modules/Apps/Routes/sections/photosRoutes';
import tracksRoutes from '@/modules/Apps/Routes/sections/tracksRoutes';
import registeredUsersRoutes from '@/modules/Apps/Routes/sections/registeredUsersRoutes';
import geofenceRoutes from '@/modules/Apps/Routes/sections/geofenceRoutes';
import campaignsRoutes from "@/modules/Apps/Routes/sections/campaignsRoutes";
import configuratorRoutes from "@/modules/Apps/Routes/sections/configuratorRoutes";
import urlRoutes from "@/modules/Apps/Routes/sections/urlRoutes";
import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";

let initRoutes = [
    {
      path: '',
      name: 'manage-app',
      component: () => import('@/modules/Apps/Pages/AppMenu.vue'),
      meta: {
        parent: "apps",
        pageTitle: 'apps.manage.manage',
        breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getAppPanelTitle, url: `/apps/:uuid`, active: true },
        ]
      },
    },
];

export let appsRoutes = [
  ...initRoutes,
  ...bannerRoutes,
  ...bannerInterstitialRoutes,
  ...contactphoneRoutes,
  ...promoassetsRoutes,
  ...photosRoutes,
  ...tracksRoutes,
  ...registeredUsersRoutes,
  ...geofenceRoutes,
  ...campaignsRoutes,
  ...configuratorRoutes,
  ...urlRoutes
];
