import Vue from "vue";
import { shopapi } from "@/axios.js";
import {modulesWithDRM} from '@/modules/Auth/Helpers/drmHelper.js';
import router from '@/router'

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;
const withoutResort = ["resorts", "product_types", "period_ranges", "statuses"];

export default {
  state: {
    module: "generic",
    resource: [],
    element: {},
    currentPage: 1,
    totalPages: 1,
    perPage: 15,
    isLoading: false
  },

  getters: {
    getAll: state => state.resource,
    getIndexByFilter: state => delegate => state.resource.findIndex(delegate),
    getIndexById: state => id => state.resource.findIndex(x => x.id === id),
    getElement: state => state.element,
    getById: state => id => state.resource.find(x => x.id === id),
    getByFilter: state => delegate => state.resource.filter(delegate),
    getByMap: state => delegate => state.resource.map(delegate),
    getFirst: state => delegate => state.resource.find(delegate),
    getCurrentPage: state => state.currentPage,
    getTotalPages: state => state.totalPages,
    getPerPage: state => state.perPage,
    getIsLoading: state => state.isLoading
  },

  actions: {
    async loadById(context, payload) {
      context.commit("SET_IS_LOADING", true);
      let url = `/api/${version}/${context.state.module}/${payload.id}`;

      const response = await shopapi.get(url);

      if (response.status === 200) {
        const resource = response.data;
        context.commit("SET_BY_ID", resource);
      } else {
        // Handle error case
        console.error(response);
      }

      context.commit("SET_IS_LOADING", false);
    },

    async load(context, payload = { page: 1, page_size: 10, sort: "+id" }) {
      context.commit("SET_IS_LOADING", true);
      let url = `/api/${version}/resorts/${router.currentRoute.params.uuid}/${context.state.module}`;

      if (withoutResort.includes(context.state.module)) {
        url = `/api/${version}/${context.state.module}`;
      }

      const response = await shopapi.get(url, { params: payload });

      if (response.status === 200) {
        // All responses are paginated, objects are in response.data.data
        const resource = response.data.data;

        context.commit("SET_ALL", resource);

        context.commit("SET_CURRENT_PAGE", parseInt(response.data.current_page));
        context.commit("SET_TOTAL_PAGES", parseInt(response.data.last_page));
        context.commit("SET_PER_PAGE", parseInt(response.data.per_page));
      } else {
        // Handle error case
        console.error(response);
      }

      context.commit("SET_IS_LOADING", false);
    },

    async create(context, payload) {
      context.commit("SET_IS_LOADING", true);

      payload.resort_id = context.rootGetters["shops/resorts/resort"].id;
      payload.resorts = [];
      payload.resorts.push(payload.resort_id);

      const response = modulesWithDRM.includes(context.state.module)
        ? await shopapi.post(`/api/${version}/resorts/${payload.resort_id}/${context.state.module}`, payload).catch(error => {throw error})
        : await shopapi.post(`/api/${version}/${context.state.module}`, payload).catch(error => {throw error})

      if (response.status === 200 || response.status === 201) {
        const resource = response.data;

        context.commit("ADD_TO_EXISTING", resource);

        context.commit("SET_IS_LOADING", false);
        return context.getters["getById"](resource.id);
      } else {
        // Handle error case
        console.warn(response);
        context.commit("SET_IS_LOADING", false);
        return null;
      }
    },

    async update(context, payload) {
      context.commit("SET_IS_LOADING", true);

      const response = modulesWithDRM.includes(context.state.module)
        ? await shopapi.put(`/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/${context.state.module}/${payload.id}`, payload).catch(error => {throw error})
        : await shopapi.put(`/api/${version}/${context.state.module}/${payload.id}`, payload).catch(error => {throw error})

      if (response.status === 200) {
        const index = context.getters["getIndexById"](payload.id);

        context.commit("SET_BY_INDEX", { index, resource: payload });
      } else {
        // Handle error case
        console.warn(response);
      }

      context.commit("SET_IS_LOADING", false);
    },

    async remove(context, payload) {
      context.commit("SET_IS_LOADING", true);

      const response = modulesWithDRM.includes(context.state.module)
        ? await shopapi.delete(`/api/${version}/resorts/${context.rootGetters["shops/resorts/resort"].id}/${context.state.module}/${payload.id}`)
        : await shopapi.delete(`/api/${version}/${context.state.module}/${payload.id}`);

      if (response.status === 204) {
        //

        const index = context.getters["getIndexById"](payload.id);

        context.commit("REMOVE_BY_INDEX", index);
      } else {
        // Handle error case
        console.error(response);
      }

      context.commit("SET_IS_LOADING", false);
    },

    assignTranslations(context, payload) {
      if (typeof payload.translations === "undefined") {
        return;
      }

      if (!Array.isArray(payload.translations)) {
        return;
      }

      payload.translations.forEach(translation => {
        // payload[translation.locale]["name"] = translation.name;
        // payload[translation.locale]["description"] = translation.description;

        if (translation.locale === "es") {
          payload.es.name = translation.name;
          payload.es.description = translation.description;
        }
        if (translation.locale === "fr") {
          payload.fr.name = translation.name;
          payload.fr.description = translation.description;
        }
        if (translation.locale === "de") {
          payload.de.name = translation.name;
          payload.de.description = translation.description;
        }
        if (translation.locale === "no") {
          payload.no.name = translation.name;
          payload.no.description = translation.description;
        }
        if (translation.locale === "pl") {
          payload.pl.name = translation.name;
          payload.pl.description = translation.description;
        }
        if (translation.locale === "sl") {
          payload.sl.name = translation.name;
          payload.sl.description = translation.description;
        }
        if (translation.locale === "sk") {
          payload.sk.name = translation.name;
          payload.sk.description = translation.description;
        }
      });
    },

    resetResource(context) {
      context.commit("RESET_RESOURCE");
    },

    setElement(context, payload) {
      context.commit("SET_BY_ID", payload)
    }
  },

  mutations: {
    // Pagination
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },

    SET_TOTAL_PAGES(state, payload) {
      state.totalPages = payload;
    },

    SET_PER_PAGE(state, payload) {
      state.perPage = payload;
    },

    // Resources
    SET_ALL(state, payload) {
      state.resource = payload;
    },

    SET_BY_ID(state, payload) {
      state.element = payload;
    },

    SET_BY_INDEX(state, { index, resource }) {
      Vue.set(state.resource, index, resource);
    },

    ADD_TO_EXISTING(state, payload) {
      if (Array.isArray(payload)) {
        state.resource = state.resource.concat(payload);
      } else {
        state.resource.push(payload);
      }
    },

    REMOVE_BY_INDEX(state, index) {
      state.resource.splice(index, 1);
    },

    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },

    RESET_RESOURCE(state) {
      state.resource = []
    }
  }
};
