/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import store from './modules/Shared/Store/store'
import i18n from '@/i18n.js'
import Router from 'vue-router'
import {appsRoutes} from './modules/Apps/Routes/appsRoutes'
import {resortsRoutes} from './modules/Resorts/Routes/resortsRoutes'
import {shopsRoutes} from './modules/Shops/Routes/shopsRoutes'
import AuthServices from './modules/Auth/Services/AuthServices.js'
import {hasAuthorization} from './modules/Auth/Helpers/drmHelper.js'
import { getResourcesWithStatisticsDrm } from "./modules/Statistics/Services/StatisticsService";
import {adminRoutes} from "@/modules/Admin/Routes/adminRoutes";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('@/layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home',
                component: () => import('@/modules/Home/Pages/Home.vue'),
                meta: {
                  pageTitle: 'dashboard.title',
                },
                beforeEnter: (to, from, next) => {
                  const drm = store.getters['auth/drm']

                  if (drm.admin.length === 0 && drm.apps.length === 0 && drm.resorts.length === 0 && drm.shops.length === 0) {
                    store.dispatch('auth/logout')
                  }

                  if (drm.admin.length > 1 || (drm.admin.length === 1 && !drm.admin[0].drm.includes('ria'))) {
                    return next({ path: '/admin' })
                  }

                  if (
                    getResourcesWithStatisticsDrm('app').length !== 0 ||
                    getResourcesWithStatisticsDrm('resort').length !== 0 ||
                    getResourcesWithStatisticsDrm('shop').length !== 0
                  ) {
                    return next()
                  }

                  if (drm.apps.length > 0) {
                    const app = drm.apps[0]
                    return next({ path: '/apps/' + app.uuid })
                  }
                  if (drm.resorts.length > 0) {
                    const resort = drm.resorts[0]
                    return next({ path: '/resorts/' + resort.uuid })
                  }
                  if (drm.shops.length > 0) {
                    const shop = drm.shops[0]
                    return next({path: '/shops/' + shop.uuid})
                  }
                }
              }
            ],
        },
        {
    // =============================================================================
    // ADMIN SPECIFIC ROUTES
    // =============================================================================
            path: "/admin",
            component: () => import('@/layouts/LayoutManager.vue'),
            children: adminRoutes,
            beforeEnter: (to, from, next) => {
                const drm = store.getters['auth/drm']

                if (drm.admin.length === 0) {
                    next({ path: '/' })
                }

                next()
            }
        },
        {
    // =============================================================================
    // APP SPECIFIC ROUTES
    // =============================================================================
            path: "/apps/:uuid",
            component: () => import('@/layouts/LayoutManager.vue'),
            children: appsRoutes
        },
        {
    // =============================================================================
    // RESORT SPECIFIC ROUTES
    // =============================================================================
            path: "/resorts/:uuid",
            component: () => import('@/layouts/LayoutManager.vue'),
            children: resortsRoutes
        },
        {
    // =============================================================================
    // SHOPS SPECIFIC ROUTES
    // =============================================================================
            path: "/shops/:uuid",
            component: () => import('@/layouts/main/Main.vue'),
            children: shopsRoutes
        },
        {
    // =============================================================================
    // SPECIFIC ROUTE TO ZENDESK
    // =============================================================================
            path: "/helpdesk",
            redirect: async () => {
              window.location.href = 'https://spotlio.zendesk.com/'
            },
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '/:language([a-z]{2})',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: 'login',
                name: 'login',
                component: () => import('@/modules/Auth/Pages/Login.vue')
              },
              {
                path: 'password-forget',
                name: 'password-forget',
                component: () => import('@/modules/Auth/Pages/PasswordForget.vue')
              },
              {
                path: '404',
                name: '404',
                component: () => import('@/modules/Shared/Pages/Error404.vue')
              }
            ],
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/en/404'
        }
    ],
})

const publicPages = ['login', 'password-forget', '404']

router.beforeEach(async (to, from, next) => {
  const authRequired = !publicPages.includes(to.name)

  let language = to.params.language ? to.params.language : store.getters.applicationLanguage;
  i18n.locale = language;

  if (!authRequired) {
    return next();
  }

  try {
    const loggedIn = await AuthServices.loggedIn();
    if (!loggedIn) {
      return next({
        path: `/${language}/login`,
        query: { returnUrl: to.path }
      });
    }
  } catch (error) {
    console.log(`Error when checking if user is logged in: ${error}`)
  }

  // DRM
  if (!hasAuthorization(to)) {
    return next('/')
  }

  next();
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
