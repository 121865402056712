import store from "@/modules/Shared/Store/store.js";

export let shopsRoutes = [
  {
    path: "",
    props: true,
    name: "manage-shop",
    component: () =>
      import(
        /* webpackChunkName: "shop-management" */ "@/modules/Shops/Pages/ShopMenu.vue"
      ),
    meta: {
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.manage_shop"
      // rule: "admin"
    }
  },
  {
    path: "dashboard",
    props: true,
    name: "shop-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "shop-dashboard" */ "@/modules/Shops/Pages/ShopsDashboard.vue"
      ),
    meta: {

      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.dashboard.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.dashboard.title",
      // rule: "admin"
    }
  },

  {
    path: "catalogs",
    name: "Catalogs",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-catalogs" */ "@/modules/Shops/Pages/data/Catalogs.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'catalog' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.catalogs.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.catalogs.title",
      // rule: "admin"
    }
  },
  {
    path: "products",
    name: "Products",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-products" */ "@/modules/Shops/Pages/data/Products.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'products' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.products.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.products.title",
      // rule: "admin"
    }
  },
  {
    path: "participants",
    name: "Participants",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-participants" */ "@/modules/Shops/Pages/data/Participants.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'participants' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.participants.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.participants.title",
      // rule: "admin"
    }
  },
  {
    path: "snippets",
    name: "Snippets",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-snippets" */ "@/modules/Shops/Pages/data/Snippets.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'snippets' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shop.snippets.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shop.snippets.title",
      // rule: "admin"
    }
  },
  {
    path: "hooks",
    name: "Hooks",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-hooks" */ "@/modules/Shops/Pages/data/Hooks.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'hooks' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shop.hooks.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shop.hooks.title",
      // rule: "admin"
    }
  },
  {
    path: "variable-pricing",
    name: "VariablePricing",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-variable-pricing" */ "@/modules/Shops/Pages/data/VariablePricing.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'dynamicpricing' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.variable-pricing.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.variable-pricing.title",
      // rule: "admin"
    }
  },
  {
    path: "marketing-periods",
    name: "MarketingPeriods",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-periods" */ "@/modules/Shops/Pages/data/Periods.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'marketingperiods' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.marketing_periods.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.marketing_periods.title",
    }
  },
  {
    path: "periods",
    name: "Periods",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-periods" */ "@/modules/Shops/Pages/data/Periods.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'periods' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.catalog_periods.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.catalog_periods.title",
      rule: "admin"
    }
  },
  {
    path: "prices",
    name: "Prices",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-prices" */ "@/modules/Shops/Pages/data/Prices.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'prices' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.prices.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.prices.title",
      rule: "admin"
    }
  },
  {
    path: "promotions",
    name: "Promotions",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-promotions" */ "@/modules/Shops/Pages/data/Promotions.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'promotions' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.promotions.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.promotions.title"
      // rule: 'editor'
    }
  },
  {
    path: "orders",
    name: "Orders",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-orders" */ "@/modules/Shops/Pages/data/Orders.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'orders' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.orders.title", active: true }
      ],
      parent: "shops",
      pageTitle: "shops.orders.title"
      // rule: 'editor'
    }
  },
  {
    path: "resort",
    name: "Resort",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-orders" */ "@/modules/Shops/Pages/data/Resort.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'resorts' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.resorts.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.resorts.title',
      // rule: 'editor'
    }
  },
  {
    path: "customers",
    name: "Customers",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-customers" */ "@/modules/Shops/Pages/data/Customers.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'users' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.customers.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.customers.title',
      // rule: 'editor'
    }
  },
  {
    path: "pages",
    name: "Pages",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-pages" */ "@/modules/Shops/Pages/data/Pages.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'pages' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.pages.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.pages.title',
      // rule: 'editor'
    }
  },
  {
    path: "payments",
    name: "Payments",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-payments" */ "@/modules/Shops/Pages/data/Payments.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'payments' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.payments.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.payments.title',
      // rule: 'editor'
    }
  },
  {
    path: "access-point",
    name: "AccessPoint",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-access-point" */ "@/modules/Shops/Pages/forms/AccessPointForm.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'accespoint' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.access-point.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.access-point.title',
      // rule: 'admin'
    }
  },
  {
    path: "taxes",
    name: "Taxes",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-taxes" */ "@/modules/Shops/Pages/data/Taxes.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'taxes' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.taxes.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.taxes.title',
      // rule: 'admin'
    }
  },
  {
    path: "paymentGateways",
    name: "PaymentGateways",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-payment_gateways" */ "@/modules/Shops/Pages/data/PaymentGateways.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'paymentgateways' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.paymentGateways.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.paymentGateways.title',
      // rule: 'admin'
    }
  },
  {
    path: "capacity",
    name: "Capacity",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-capacity" */ "@/modules/Shops/Pages/data/Capacity.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'capacity' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.capacity.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.capacity.title',
      // rule: 'admin'
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('shops/periods/load').then(() => {
        store.dispatch('shops/capacities/getCapacityTable').then(() => {
          next()
        })
      })
    }
  },
  {
    path: "order_details",
    name: "OrderDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-order_details" */ "@/modules/Shops/Pages/data/OrderDetails.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'orders' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.order_details.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.order_details.title',
    }
  },
  {
    path: "sales",
    name: "Sales",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-sales" */ "@/modules/Shops/Pages/data/Sales.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'sales' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.sales.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.sales.title',
    }
  },
  {
    path: "email_configuration",
    name: "EmailConfiguration",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-order_details" */ "@/modules/Shops/Pages/forms/EmailConfigurationForm.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'email' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: 'shops.email_configuration.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.email_configuration.title',
    }
  },
  {
    path: "orders/:order_id",
    name: "OrderInfo",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "shop-order-info" */ "@/modules/Shops/Pages/data/OrderInfo.vue"
      ),
    meta: {
      drm: [
        { context: 'shops', drm: 'orders' }
      ],
      breadcrumb: [
        { title: "Home", url: "/shops/:uuid" },
        { title: "shops.manage_shop", url: "/shops/:uuid" },
        { title: "shops.orders.title", url: "/shops/:uuid/orders" },
        { title: 'shops.order_info.title', active: true }
      ],
      parent: "shops",
      pageTitle: 'shops.order_info.title',
    }
  },
];
