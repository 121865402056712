import {getAppPanelTitle} from "@/modules/Apps/Helpers/routeHelper";

export default [
  {
    path: '/apps/:uuid/campaigns',
    name: 'push-messages',
    component: () => import(
      /* webpackChunkName: "schedule-campaign" */ "@/modules/Apps/Pages/campaigns/ScheduleCampaign.vue"
    ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.pushs.title',
      drm: [
        { context: 'apps', drm: 'push' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.pushs.title', active: true },
      ]
    }
  },
  {
    path: '/apps/:uuid/campaigns/:campaignUuid/edit',
    name: 'edit-campaign',
    component: () => import(
      /* webpackChunkName: "edit-campaign" */ "@/modules/Apps/Pages/campaigns/EditCampaign.vue"
      ),
    meta: {
      parent: "apps",
      pageTitle: 'apps.pushs.title',
      drm: [
        { context: 'apps', drm: 'push' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getAppPanelTitle, url: `/apps/:uuid` },
        { title: 'apps.pushs.title', active: true },
      ]
    }
  }
]
