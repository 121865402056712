import genericStore from "@/modules/Shops/Store/genericStore";

export default {
  namespaced: true,

  state: {
    ...genericStore.state,
    module: "order_details"
  },

  getters: {
    ...genericStore.getters
  },

  actions: {
    ...genericStore.actions
  },

  mutations: {
    ...genericStore.mutations
  }
}
