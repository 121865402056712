import axios from '@/axios.js'
const baseUrl = `languages/v3`;
export default {
    getLanguages : async () => {
        try {
            const response = await axios.publicapi.get(baseUrl)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
