/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from '@/modules/Auth/Store/moduleAuth.js'
import moduleApps from '@/modules/Apps/Store/moduleApps.js'
import moduleResorts from '@/modules/Resorts/Store/moduleResorts.js'
import moduleShops from '@/modules/Shops/Store/moduleShops.js'
import moduleStatistics from '@/modules/Statistics/Store/moduleStatistics.js'

//Init persistance plugin
//https://alligator.io/vuejs/vuex-persist-state/

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      auth: moduleAuth,
      apps: moduleApps,
      resorts: moduleResorts,
      shops: moduleShops,
      statistics: moduleStatistics,
    },
    strict: process.env.NODE_ENV !== 'production',
    plugins: [vuexLocalStorage.plugin]
})
