import store from "../../Shared/Store/store";

export const userHasAuthorization = (permissionsArray) => {
  return Boolean(permissionsArray.find(permissionItem => {
    const {context, uuid, drm} = permissionItem
    if (getUserResourceDrmList(context, uuid).includes(drm)) {
      return true
    }
  }))
}

export const hasAuthorization = (route) => {
  try {
    if (!route.meta.hasOwnProperty('drm')) {
      return true
    }

    const permissionsArray = route.meta.drm.map(permissionItem => ({...permissionItem, uuid: route.params.uuid}))
    return userHasAuthorization(permissionsArray)
  } catch (error) {
    return false
  }
}

export const getUserResourceDrmList = (context, uuid) => {
  try {
    if (context === 'admin') {
      return store.state.auth.drm['admin'].length
        ? store.state.auth.drm['admin'][0].drm
        : []
    }
    return store.state.auth.drm[context]
      .find(resource => resource.uuid === uuid)
      .drm
  } catch (error) {
    return [];
  }
}

export const modulesWithDRM = ['orders', 'order_details', 'promotions', 'dynamic_pricings', 'capacities']
