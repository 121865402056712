import { shopapi } from "@/axios.js";
import genericStore from "@/modules/Shops/Store/genericStore";

const version = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  namespaced: true,

  state: {
    ...genericStore.state,
    module: "resorts",
    _resort: {},
    _resortConfig: {},
    _resortEmailConfig: {},
    _resortCatalog: {}
  },

  getters: {
    ...genericStore.getters,
    resort: state => state._resort,
    resortConfig: state => state._resortConfig,
    resortEmailConfig: state => state._resortEmailConfig,
    resortCatalog: state => state._resortCatalog,
    resortSystem: state => state._resortConfig.system,
  },

  actions: {
    ...genericStore.actions,

    async setActiveResort(context, resortId) {
      try {
        const response = await shopapi.get(`/api/${version}/resorts/${resortId}`);
        context.commit("SET_RESORT", response.data);
      } catch (e) {
        context.commit("SET_RESORT", {});
      }
    },

    async loadResortConfig(context, resortId) {
      const response = await shopapi.get(`/api/${version}/resorts/${resortId}/acs_config`);

      if (response.status === 200) {
        context.commit("SET_RESORT_CONFIG", response.data);
      } else {
        console.warn("Status is not 200: ", response);
      }
    },

    async setResortConfig(context, payload) {
      const response = await shopapi.post(`/api/${version}/resorts/${payload.id}/acs_config`, payload.values);

      if (response.status === 200) {
        context.commit("SET_RESORT_CONFIG", response.data);
      } else {
        console.warn("Status is not 200: ", response);
      }
    },

    async loadResortEmailConfig(context, resortId) {
      const response = await shopapi.get(`/api/${version}/resorts/${resortId}/email_config`);

      if (response.status === 200) {
        context.commit("SET_RESORT_EMAIL_CONFIG", response.data);
      } else {
        console.warn("Status is not 200: ", response);
      }
    },

    async setResortEmailConfig(context, payload) {
      const response = await shopapi.post(`/api/${version}/resorts/${payload.resort_id}/email_config`, payload);

      if (response.status === 200) {
        context.commit("SET_RESORT_EMAIL_CONFIG", response.data);
      } else {
        console.warn("Status is not 200: ", response);
      }
    },

    async loadResortCatalog(context, resortId) {
      const response = await shopapi.get(`/api/${version}/resorts/${resortId}/acs_catalog`);

      if (response.status === 200) {
        context.commit("SET_RESORT_CATALOG", response.data);
      } else {
        console.warn("Status is not 200: ", response);
      }
    }
  },

  mutations: {
    ...genericStore.mutations,

    SET_RESORT(state, payload) {
      state._resort = payload;
    },

    SET_RESORT_CONFIG(state, payload) {
      state._resortConfig = payload;
    },

    SET_RESORT_EMAIL_CONFIG(state, payload) {
      state._resortEmailConfig = payload;
    },

    SET_RESORT_CATALOG(state, payload) {
      state._resortCatalog = payload;
    }
  }
};
